import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import { UIReducer, UIState } from './ui/ui.reducer';

export interface AppState {
  ui: UIState;
}

const reducer = combineReducers<AppState>({
  ui: UIReducer
});

const store: Store<AppState> = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export { store };
