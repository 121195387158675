import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';

// Antd
import Spin from 'antd/es/spin';

// Rutas
// import { AdminRoutes } from 'app/modules/admin/admin.routes';

// Pages
const HomePage = lazy(() => import('./pages/module.page'));
const ReportPage = lazy(() => import('./pages/report.page'));

export const ModuleRoutes = () => {
  const routesSetting: RouteProps[] = [];

  return (
    <Suspense fallback={<Spin className='fadeIn app-loading' tip='Cargando Componentes...' />}>
      <Switch>
        <Route path='/' exact component={HomePage} />
        <Route path='/report' exact component={ReportPage} />
        {routesSetting.map((i, idx) => (
          <Route key={idx} exact {...i} />
        ))}
        <Redirect to='/' />
      </Switch>
    </Suspense>
  );
};
