import { BrowserRouter } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { AppState } from 'app/redux/app.reducers';

// Antd
import Spin from 'antd/es/spin';
import Layout from 'antd/es/layout';
import BackTop from 'antd/es/back-top';

// Componentes
import { FooterComponent } from 'app/shared/components/layout/footer.component';

// Módulos
import { ModuleRoutes } from './module.routes';

// Imágenes & Documentos
import LogoNegativo from '../../assets/images/brand/logo_alcaldia.png';

// Fragmentos
const { Content } = Layout;

export const ModuleLayout = () => {
  //#region Redux settings

  const loading = useSelector<AppState, boolean>((state) => state.ui.loading);

  //#endregion

  const assetsDocuments = require.context('../../assets/documents', true);

  return (
    <BrowserRouter>
      {loading && <Spin className='fadeIn app-loading' tip='Cargando Componentes...' />}
      <Layout className='fadeIn' style={{ minHeight: '100vh' }}>
        <Content>
          <ModuleRoutes />
        </Content>
        <FooterComponent className='container'>
          <div className='mt-3 d-flex justify-content-between align-items-center'>
            <ul className='list-unstyled mb-0'>
              <li>
                <a href={assetsDocuments('./Politica_Proteccion_Datos.pdf').default} rel='noreferrer' target='_blank'>
                  Política de Protección de Datos
                </a>
              </li>
            </ul>
            <img src={LogoNegativo} alt='Logotipo' height={50} />
          </div>
        </FooterComponent>
        <BackTop />
      </Layout>
    </BrowserRouter>
  );
};
