/** Ficha técnica del proyecto. */
/* TODO: [2021-06-06] Hacer las modificaciones respectivas por proyecto. */
const projectInfo = {
  name: 'Vacunados en el Exterior',
  fullname: 'Secretaría Distrital de Salud',
  description:
    'Este dato nos ayudará a conocer la cifra total de personas vacunadas y el porcentaje de avance hacia la inmunidad de rebaño. La información que suministres únicamente será registrada con fines epidemiológicos y solo será usada por la Secretaría de Salud para tomar decisiones informadas.',
  version: 'v.2.0.3.',
  versionYear: 2022,
  developTo: 'La Secretaria Distrital de Salud',
  recommendedBrowsers: 'Navegadores Web recomendados: Chrome 43 o superior, Firefox 38 o superior, Safari 8 o superior.',
  information:
    'Todos los derechos reservados. Ninguna reproducción externa copia o transmisión digital de esta publicación puede ser hecha sin permiso escrito. Ningún párrafo de esta publicación puede ser reproducido, copiado o transmitido digitalmente sin un consentimiento escrito o de acuerdo con las leyes que regulan los derechos de autor y con base en la regulación vigente.',
  menu: []
};

/** Expresiones regulares. */
const regExp = {
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  url: /^https?:\/\/[\w]+(\.[\w]+)+[/#?]?.*$/,
  notSpecialCharacters: /^[A-Za-z0-9.,;:-áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/,
  notSpecialCharactersStrict: /^[A-Za-z0-9\s]+$/,
  number: /^[0-9]+$/,
  isString: /^[A-Za-z\s?]+$/,
  notSpecialCharactersString: /^[A-Za-z.,;:-áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/,
  notSpecialCharactersStrictAndSpaces: /^[A-Za-z0-9]+$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).*$/
};

export { projectInfo, regExp };
